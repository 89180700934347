import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-656f7c15"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "header"
};
var _hoisted_2 = {
  "class": "title"
};
import { useRouter, useRoute } from "vue-router";
import trackEvent from "@/utils/trackEvent";
export default {
  __name: 'header',
  props: {
    title: String,
    backTrackEvent: String,
    offerIdEvent: String
  },
  setup: function setup(__props) {
    var router = useRouter();
    var route = useRoute();
    var props = __props;
    var goBack = function goBack() {
      // 关闭页面埋点
      trackEvent(props.backTrackEvent, {
        eb_key2: props.offerIdEvent
      }, 513);
      router.go(-1);
    };
    return function (_ctx, _cache) {
      var _component_van_icon = _resolveComponent("van-icon");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_icon, {
        name: "down",
        "class": "back",
        onClick: goBack
      }), _createElementVNode("h1", _hoisted_2, _toDisplayString(__props.title), 1), _renderSlot(_ctx.$slots, "right")]);
    };
  }
};