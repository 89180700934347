import request from "@/utils/request";

// login
export function loginApi(params) {
  return request({
    url: '/sp-apis/acct/v1.0/user/login',
    data:params,
    method: 'post'
  });
}

// autoLogin 进入页面默认调用的游客接口
export function autoLoginApi(params) {
  return request({
    url: '/sp-apis/acct/v1.0/user/autoLogin',
    data:params,
    method: 'post'
  });
}

// 绑定邮箱
export function registerApi(params) {
  return request({
    url: '/sp-apis/acct/v1.0/user/register',
    data:params,
    method: 'post'
  });
}

// ab接口
export function abTestApi(params) {
  return request({
    url: '/sp-apis/ab/getAb',
    data:params,
    method: 'post'
  });
}

// 用户信息
export function getUserInfoApi(params) {
  return request({
    url: '/sp-apis/acct/v1.0/user/get/info',
    data:params,
    method: 'post'
  });
}

// 刷新用户信息
export function refreshUserInfoApi(params) {
  return request({
    url: '/sp-apis/acct/v1.0/user/token/refresh',
    data:params,
    method: 'post'
  });
}
