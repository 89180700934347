import request from "@/utils/request";

// 金币账户
export function cashUserApi(params) {
  return request({
    url: '/sp-apis/acct/v1.0/user/balance/vgs/query',
    data:params,
    method: 'post'
  });
}

// 档位列表
export function targetListApi(params) {
  return request({
    url: '/sp-apis/txs/redeem/target/list',
    data:params,
    method: 'post'
  });
}

const cashOutUrl = process.env.NODE_ENV !== "production" ? 'https://t3.9snail.com' : 'https://pncxqi.com' // 打款提现接口

// paypal打款接口
export function paypalApi(params) {
  return request({
    url: `${cashOutUrl}/vgs-payment/v1.0/wrapper/pagsmile/payment`,
    data: params,
    method: 'post'
  });
}

// amazon打款接口
export function amazonApi(params) {
  return request({
    url: `${cashOutUrl}/vgs-payment/v1.0/wrapper/card/redeemCode`,
    data:params,
    method: 'post'
  });
}

// 提现记录
export function historyApi(params) {
  return request({
    url: `${cashOutUrl}/vgs-payment/v1.0/wrapper/common/history`,
    data: params,
    method: 'post'
  });
}

// 查询打款账户
export function cashOutUserApi(params) {
  return request({
    url: `/sp-apis/acct/v1.0/wd/acct/list`,
    data:params,
    method: 'post'
  });
}
