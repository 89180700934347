import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";
import index from "../views/index.vue";
import login from "../views/login.vue";
import signUp from "../views/signUp.vue";
import offerDetail from '../views/offerDetail.vue'
import cashOut from '../views/cashOut.vue'
import tutorial from '../views/tutorial.vue'
import privacyPolicy from '../views/privacyPolicy.vue'
import termsOfService from '../views/termsOfService.vue'
import webTrack from '../views/webTrack.vue'

const routes = [
  // 设置默认路由
  { path: '/', redirect: { name: 'index' } },
  {
    path: "/",
    name: "index",
    component: index,
    // meta: {
    //   keepAlive: true // 需要缓存
    // }
  },
  {
    path: "/offerDetail",
    name: "offerDetail",
    component: offerDetail,
    meta: {
      keepAlive: true // 需要缓存
    }
  },
  {
    path: "/cashOut",
    name: "cashOut",
    component: cashOut,
    // meta: {
    //   keepAlive: true // 需要缓存
    // }
  },
  {
    path: "/login",
    name: "login",
    component: login,
    // meta: {
    //   keepAlive: true // 需要缓存
    // }
  },
  {
    path: "/signUp",
    name: "signUp",
    component: signUp,
    // meta: {
    //   keepAlive: true // 需要缓存
    // }
  },
  {
    path: "/tutorial",
    name: "tutorial",
    component: tutorial,
  },
  {
    path: "/privacyPolicy",
    name: "privacyPolicy",
    component: privacyPolicy,
  },
  {
    path: "/termsOfService",
    name: "termsOfService",
    component: termsOfService,
  },
  {
    path: "/webTrack",
    name: "webTrack",
    component: webTrack,
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
});

export default router;
