import { createI18n } from 'vue-i18n'

const messages = {
  'EN': require('../language/EN.json'),
  'RUS': require('../language/RUS.json'),
}
const i18n = createI18n({
  locale: 'EN', // 默认语言
  messages
})

export default i18n
