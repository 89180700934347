const offer = {
  namespaced: true, // 启用模块命名空间
  state: {
    offerItemData:{}
  },
  mutations: {
    SET_OFFER_ITEM_DATA(state, data){
      state.offerItemData = data
    },
  },
  actions: {
    setOfferItemData({commit}, data){
      commit('SET_OFFER_ITEM_DATA',data)
    },
  },
  getters: {
    // 定义用户模块的 getters
  }
};

export default offer;
