import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref } from 'vue';
import trackEvent from "@/utils/trackEvent";

// const wt = (eventName, eventData)=>{
//   // 埋点谷歌
//   console.log('Track event:', eventName, eventData);
//   gtag('event', eventName, eventData);
// }
// window.wt = wt

export default {
  __name: 'webTrack',
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div");
    };
  }
};