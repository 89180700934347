import { createStore } from "vuex";
import user from './modules/user';
import system from './modules/system';
import offer from './modules/offer';

export default createStore({
  modules:{
    user: user,
    system: system,
    offer:offer
  },
});
