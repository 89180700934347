import JSEncrypt from 'jsencrypt'
import CryptoJS from 'crypto-js'
// 'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAKhc3TS3NT2dt3vjIqjb4Eh+e6P/0/p3+bYY9zCLcWYAdZHdbTWDtMJB9D4QFeZZ57VSss9Fk9OD43JRATiP6UMCAwEAAQ=='
const RSAKEY = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDvuF8W2ghFIJ8xj/kpXQMAh04PNrLJP9UxFigM7MROvct7MIsn4PKvqF0uOrgIMS15mvONp6JghCkDOg4TKyc/SXtz9C4E/jDHoMy6e/w24WqvvL1T8mk+EftBL2ATjQ4UtW8vQeiyuAGOqfpr7m/tzfRIEoCZq21O8LyryslhywIDAQAB'
const AESKEY = 'VIX5XN2539vQ4SQI' //  7BD2R3s8F2j833jo
const IV = 'MM1B4I6w23QhzHhG'

/**
 * 加密方法 RSA
 */
export function RSAencrypt(data) {
  let jse = new JSEncrypt()
  let publicKey = RSAKEY
  jse.setPublicKey(publicKey)
  return jse.encrypt(data)
}

/**
 * 加密方法 AES
 */
export function AESencrypt(data, iv) {
  let key = AESKEY
  key = CryptoJS.enc.Utf8.parse(key)
  iv = CryptoJS.enc.Utf8.parse(iv)
  let srcs = CryptoJS.enc.Utf8.parse(data)
  let encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
}

/**
 * 解密方法 AES
 */
export function AESdecrypt(data,ivConfig) {
  let key = AESKEY
  let iv = ivConfig ? CryptoJS.enc.Utf8.parse(ivConfig) : CryptoJS.enc.Utf8.parse(IV)
  key = CryptoJS.enc.Utf8.parse(key)
  let base64 = CryptoJS.enc.Base64.parse(data)
  let src = CryptoJS.enc.Base64.stringify(base64)
  let decrypt = CryptoJS.AES.decrypt(src, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return CryptoJS.enc.Utf8.stringify(decrypt).toString()
}

