import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2ab18701"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "signUp-container"
};
var _hoisted_2 = {
  "class": "title"
};
var _hoisted_3 = {
  "class": "passwordWrap"
};
var _hoisted_4 = {
  "class": "passwordWrap"
};
var _hoisted_5 = {
  "class": "linkTo"
};
import { ref, reactive, toRefs, onMounted } from "vue";
import { useRouter } from "vue-router";
import { registerApi } from "@/api/user";
import { setCookie } from "@/utils/auth";
import { useStore } from "vuex";
import trackEvent from '@/utils/trackEvent'; 
import { showLoadingToast, closeToast, showToast } from 'vant/es';
import 'vant/es/toast/style/index';
import 'vant/es/toast/style/index';
import 'vant/es/toast/style/index';
// 埋点

export default {
  __name: 'signUp',
  setup: function setup(__props) {
    var router = useRouter();
    var store = useStore();
    var type = ref(0);
    onMounted(function () {
      if (store.state.system.isCashOutSignUp == true) {
        type.value = 2;
      } else {
        type.value = 1;
      }
      // 打开注册页面时
      trackEvent('s_register', {
        eb_key1: type.value
      }, 513);
    });

    // 前往隐私协议
    var goPrivacy = function goPrivacy() {
      trackEvent('c_privacy', {}, 513);
      router.push('/privacyPolicy');
    };
    // 前往使用条款
    var goTerms = function goTerms() {
      trackEvent('c_terms', {}, 513);
      router.push('/termsOfService');
    };

    /*
      密码显示隐藏
    */
    var showPassword = ref(false);
    var showConfirmPassword = ref(false);
    var togglePasswordVisibility = function togglePasswordVisibility(type) {
      if (type == 'showPassword') {
        showPassword.value = !showPassword.value;
      }
      if (type == 'confirmPassword') {
        showConfirmPassword.value = !showConfirmPassword.value;
      }
    };

    /*
     表单
     */

    // 表单数据
    var formData = reactive({
      email: '',
      password: '',
      confirmPassword: ''
    });

    // 表单验证
    var emailValidator = function emailValidator(value) {
      var reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (!reg.test(value)) {
        return 'Invalid email format';
      }
    };
    var passWordValidator = function passWordValidator(value) {
      if (value.length < 6) {
        return 'Please enter a password more than 6 digits.';
      }
      if (value.length > 64) {
        return 'Password digits cannot exceed 64.';
      }
    };
    var confirmPasswordValidator = function confirmPasswordValidator(value) {
      if (value !== formData.password) {
        return 'Password inconsistency';
      }
      if (value.length < 6) {
        return 'Please enter a password more than 6 digits.';
      }
      if (value.length > 64) {
        return 'Password digits cannot exceed 64.';
      }
    };

    /*
     注册
     */
    var signUpFun = function signUpFun() {
      trackEvent('c_register', {
        eb_key1: type.value
      }, 513);
      showLoadingToast({
        message: 'Loading...',
        forbidClick: true
      });
      var params = {
        requestData: {
          basic: store.state.system.basicConfig,
          email: formData.email,
          password: formData.password,
          timeZone: store.state.system.timeZone
          // muid: store.state.system.basicConfig.uuid
        }
      };
      registerApi(params).then(function (res) {
        closeToast();
        if (res.code == 200) {
          trackEvent('c_register_suc', {
            eb_key1: type.value
          }, 513);
          var userInfo = res.data;
          // 获取到用户信息userId，uuid，token， 存储到cookie
          setCookie('uIf', JSON.stringify(userInfo));
          showToast('Sign up success');
          // 提现页面调过来的，则注册后返回提现页
          if (store.state.system.isCashOutSignUp == true) {
            router.replace('/cashOut');
          } else {
            router.replace('/');
          }
        } else {
          trackEvent('c_register_fail', {
            eb_key1: type.value,
            err_code: res.code,
            err_msg: res.message
          }, 513);
          showToast(res.message);
        }
      })["catch"](function (error) {
        console.log('error', error);
        trackEvent('c_register_fail', {
          eb_key1: type.value
        }, 513);
        closeToast();
        showToast('Network busy, sign up fail');
      });
    };

    /*
      返回首页
    */
    var toIndex = function toIndex() {
      trackEvent('h_register', {
        eb_key1: type.value
      }, 513);
      // 返回时打开首页侧边栏
      store.dispatch('system/setLeftSwitch', true);
      router.push({
        path: '/'
      });
    };
    var toLogin = function toLogin() {
      trackEvent('c_login', {
        eb_key1: type.value
      }, 513);
      router.push('/login');
    };
    return function (_ctx, _cache) {
      var _component_van_icon = _resolveComponent("van-icon");
      var _component_van_field = _resolveComponent("van-field");
      var _component_van_cell_group = _resolveComponent("van-cell-group");
      var _component_van_button = _resolveComponent("van-button");
      var _component_van_form = _resolveComponent("van-form");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_icon, {
        name: "down",
        "class": "back",
        onClick: toIndex
      }), _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("signUp.title")), 1), _createVNode(_component_van_form, {
        onSubmit: signUpFun,
        "class": "formWrap"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_van_cell_group, {
            inset: ""
          }, {
            "default": _withCtx(function () {
              return [_createVNode(_component_van_field, {
                "class": "commonInput",
                modelValue: formData.email,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
                  return formData.email = $event;
                }),
                name: _ctx.$t('signUp.email'),
                placeholder: _ctx.$t('signUp.email'),
                rules: [{
                  required: true,
                  message: _ctx.$t('signUp.emailRequired'),
                  trigger: 'blur'
                }, {
                  validator: emailValidator,
                  trigger: 'blur'
                }]
              }, null, 8, ["modelValue", "name", "placeholder", "rules"]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_van_field, {
                modelValue: formData.password,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
                  return formData.password = $event;
                }),
                name: _ctx.$t('signUp.password'),
                placeholder: _ctx.$t('signUp.password'),
                maxlength: "64",
                rules: [{
                  required: true,
                  message: _ctx.$t('signUp.passwordRequired'),
                  trigger: 'blur'
                }, {
                  validator: passWordValidator,
                  trigger: 'blur'
                }],
                type: showPassword.value == true ? 'text' : 'password'
              }, null, 8, ["modelValue", "name", "placeholder", "rules", "type"]), _createVNode(_component_van_icon, {
                "class": "passwordEye",
                name: showPassword.value ? 'eye' : 'closed-eye',
                onClick: _cache[2] || (_cache[2] = function ($event) {
                  return togglePasswordVisibility('showPassword');
                })
              }, null, 8, ["name"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_van_field, {
                modelValue: formData.confirmPassword,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
                  return formData.confirmPassword = $event;
                }),
                name: _ctx.$t('signUp.confirmPassword'),
                placeholder: _ctx.$t('signUp.confirmPassword'),
                maxlength: "64",
                rules: [{
                  validator: confirmPasswordValidator,
                  trigger: 'blur'
                }],
                type: showConfirmPassword.value == true ? 'text' : 'password'
              }, null, 8, ["modelValue", "name", "placeholder", "rules", "type"]), _createVNode(_component_van_icon, {
                name: showConfirmPassword.value ? 'eye' : 'closed-eye',
                "class": "passwordEye",
                onClick: _cache[4] || (_cache[4] = function ($event) {
                  return togglePasswordVisibility('confirmPassword');
                })
              }, null, 8, ["name"])])];
            }),
            _: 1
          }), _createElementVNode("div", null, [_createVNode(_component_van_button, {
            round: "",
            block: "",
            "native-type": "submit",
            "class": "signUp-btn"
          }, {
            "default": _withCtx(function () {
              return [_createTextVNode(_toDisplayString(_ctx.$t("signUp.signUp")), 1)];
            }),
            _: 1
          }), _createVNode(_component_van_button, {
            round: "",
            block: "",
            "native-type": "submit",
            "class": "login-btn",
            onClick: toLogin
          }, {
            "default": _withCtx(function () {
              return [_createTextVNode(_toDisplayString(_ctx.$t("signUp.login")), 1)];
            }),
            _: 1
          })])];
        }),
        _: 1
      }), _createElementVNode("span", _hoisted_5, [_createTextVNode(_toDisplayString(_ctx.$t("signUp.agree")) + " ", 1), _createElementVNode("a", {
        href: "javascript:(0)",
        onClick: goTerms
      }, _toDisplayString(_ctx.$t("signUp.TermsofService")), 1), _createTextVNode(" & "), _createElementVNode("a", {
        href: "javascript:(0)",
        onClick: goPrivacy
      }, _toDisplayString(_ctx.$t("signUp.PrivacyPolicy")), 1)])]);
    };
  }
};