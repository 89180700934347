import axios from 'axios';
import { getCookie } from "@/utils/auth";
import { showToast } from "vant";
import { useRouter } from 'vue-router'
const router = useRouter()
import { charAtRandom, tansParams } from '@/utils/common'
import md5 from 'md5'
import { AESencrypt, AESdecrypt, RSAencrypt } from '@/utils/encryp'


// 从环境变量中获取 baseURL
const baseURL = process.env.VUE_APP_BASE_URL;

// 创建 axios 实例
const request = axios.create({
  baseURL,
  timeout: 60000, // 设置请求超时时间
});

const md5Key = 'L9gh=p%Eozfn2#@'

// 埋点接口
var trackAPI = ['/service_stats/v3/collect/encrypt']
// 免token白名单URL
var whiteList = [trackAPI[0], '/sp-apis/acct/v1.0/user/autoLogin'];
// 不需要s的
var nots = [trackAPI[0], '/ops-task/task/report/event']

// 获取URL的路径部分
function getURLPath(url) {
  try {
    const parsedURL = new URL(url);
    return parsedURL.pathname;
  } catch (e) {
    // console.error('Invalid URL:', url);
    return '';
  }
}

// 请求拦截器
request.interceptors.request.use(
  config => {
    // 加密
    // business aseiv, cash event iv
    const timer = new Date().getTime()
    const aesIv = charAtRandom()
    config.headers['s-encode-v1'] = 's0523' // 加密方式
    config.headers['s-encode-rsa'] = RSAencrypt(aesIv) // aes iv 通过rsa进行加密后的字符
    const sign = md5(md5Key + config.data + md5Key + timer + md5Key)
    config.headers['s-encode-sign'] = sign // md5签名
    config.headers['s-encode-timestamp'] = timer // 时间戳

    // 埋点接口
    const trackUrl = getURLPath(config.url);
    if (trackAPI.includes(trackUrl)) {
      let url = config.url + `?request_data=${encodeURIComponent(config.params.request_data)}&sign=${config.params.sign}&timestamp=${config.params.timestamp}`
      config.params = {}
      config.url = url
    }else{
      console.log('%c ###请求接口数据：','color:orange',config.url, config.data )
      // 普通接口
      if(config.data.requestData){
        console.log('config.data.requestData',config.data.requestData)
        config.data = {
          request_data: AESencrypt(JSON.stringify(config.data.requestData), aesIv)
        }
      }else{
        // tp接口
        const formData = new FormData();
        const data = AESencrypt(config.data, aesIv)
        formData.set('request_data', data);
        config.data = formData
      }
    }

    // 是否上传token
    const path = getURLPath(config.url);
    if (whiteList.includes(path) || whiteList.includes(config.url)) {
      console.log('White-listed URL:', config.url);
      return config;
    }else{
      const token = JSON.parse(getCookie('uIf')).token;
      if (token) {
        config.headers.Authorization = token;
      }else{
        console.log('not token')
        showToast('Not token')
        router.push('/')
      }
      return config;
    }


  },
  error => {
    // 对请求错误操作
    return Promise.reject(error);
  }
);

// 响应拦截器
request.interceptors.response.use(
  response => {
    console.log('respone',response)
    // 对响应数据操作
    // 获取请求的路径部分
      const requestPath = getURLPath(response.config.url);
      if (nots.includes(requestPath) || nots.includes(response.config.url)) {
        if(response.data.data){
          response.data.data = response.data.data;
        }else{
          response.data = response.data;
        }
      } else {
        if(response.data.data){
          if(response.data.code == 200){
            response.data.data = JSON.parse(AESdecrypt(response.data.data))
          }else{
            response.data.data =  response.data.data
          }
          console.log('%c ###接口返回：', 'color:orange', response.config.url, response.data.data )
        }else{
          response.data = JSON.parse(AESdecrypt(response.data))
          console.log('%c ###接口返回：', 'color:orange', response.config.url, response.data)

        }
      }
    return Promise.resolve(response.data);
  },
  error => {
    // 对响应错误操作
    return Promise.reject(error);
  }
);

export default request;
