import request from "@/utils/request";

// offer列表
export function offerListApi(params) {
  return request({
    url: '/sp-apis/task/offer/offerList',
    data:params,
    method: 'post'
  });
}

// 任务列表
export function taskListApi(params) {
  return request({
    url: '/ops-task/task/get/offerEvent',
    data: params,
    method: 'post'
  });
}

// 新手任务奖励
export function beginnerRewardApi(params) {
  return request({
    url: '/ops-task/task/report/event',
    data: params,
    method: 'post'
  });
}

// 判断新手奖励是否已领取
export function beginnerListApi(params) {
  return request({
    url: '/sp-apis/txs/task/reward/items/query',
    data: params,
    method: 'post'
  });
}
