import request from "@/utils/request";

const url = process.env.NODE_ENV !== "production" ? 'https://test-sdk.9snail.com' : 'https://pncxqi.com' // 埋点接口
// snailSDK埋点接口
export function snailTrack(params) {
  return request({
    url: `${url}/service_stats/v3/collect/encrypt`,
    // url: `${url}`,
    params: params,
    method: 'post'
  });
}

// 归因提交
export function adJustConfirmApi(params) {
  return request({
    url: `/sp-apis/acct/v1.0/user/attr/confirm`,
    data: params,
    method: 'post'
  });
}
