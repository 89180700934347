/**
 * 获取时区参数
 * @returns {String}
 */
export function getTimeZone() {
    var offsetMinutes = new Date().getTimezoneOffset();
    var offsetHours = Math.abs(Math.floor(offsetMinutes / 60)); // 将偏差值转换为小时
    var offsetSign = offsetMinutes < 0 ? "+" : "-"; // 根据时区偏差的正负号确定东西半球
    return offsetSign + offsetHours.toString().padStart(2, '0');
}

/**
 * 获取url参数
 * @param {Object} String
 * @returns {String}
 */
export function getUrlParams(name) {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
}

export function getRandom(a, b) {
    var max = a
    var min = b
    if (a < b) {
        max = b
        min = a
    }
    return parseInt(Math.random() * (max - min)) + min
}
/**
 *
 * @param {bool} num 是否包含数字
 * @param {bool} upperCase 是否包含大写
 * @param {bool} lowerCase 是否包含小写
 * @param {bool} digit
 * @returns
 */

export function charAtRandom(num = true, upperCase = true, lowerCase = true, digit = 16) {
    const arr = []
    const arr1 = []
    const arr2 = []
    if (num) {
        for (let m = 0; m <= 9; m++) {
            arr.push(m)
        }
    }
    if (upperCase) {
        for (let m = 65; m <= 90; m++) {
            arr1.push(m)
        }
    }
    if (lowerCase) {
        for (let m = 97; m <= 122; m++) {
            arr2.push(m)
        }
    }
    if (!digit) {
        console.log('生成位数必传')
    }
    const mergeArr = arr.concat(arr1)
    const mergeArr1 = mergeArr.concat(arr2)
    const _length = mergeArr1.length
    let text = ''
    for (let m = 0; m < digit; m++) {
        let text1 = ''
        const random = getRandom(0, _length)
        if ((mergeArr1[random]) <= 9) {
            text1 = mergeArr1[random]
        } else if ((mergeArr1[random]) > 9) {
            text1 = String.fromCharCode(mergeArr1[random])
        }
        text += text1
    }
    return text
}


/**
 * 参数处理
 * @param {*} params  参数
 */
export function tansParams(params) {
    let result = ''
    for (const propName of Object.keys(params)) {
        const value = params[propName]
        var part = encodeURIComponent(propName) + '='
        if (value !== null && typeof (value) !== 'undefined') {
            if (typeof value === 'object') {
                for (const key of Object.keys(value)) {
                    if (value[key] !== null && typeof (value[key]) !== 'undefined') {
                        const params = propName + '[' + key + ']'
                        var subPart = encodeURIComponent(params) + '='
                        result += subPart + encodeURIComponent(value[key]) + '&'
                    }
                }
            } else {
                result += part + encodeURIComponent(value) + '&'
            }
        }
    }
    return result
}
