import { getCookie } from "@/utils/auth";
import { reactive } from "vue";
import { v4 as uuidv4 } from "uuid";
import { getTimeZone } from "@/utils/common";

const system = {
  namespaced: true, // 启用模块命名空间
  state: {
    // 配置全局basic参数
    basicConfig: {
      uuid: '', // 唯一标识
      package_name:'',
      os_language: "",
      product_id:'',
      version_code:'',
      version_name:'',
      os: "",
      android_id: '',
      google_aid: '',
      campaign:  '', // adjust归因参数
      channel:  '', // adjust归因参数
      media_source: '', // adjust归因参数
      adset: '', // adjust归因参数
      adgroup:'', // adjust归因参数
    },

    // adjust
    channelCallbackStatus: false,
    adjustData: {},
    confirmAdjustStatus: false,

    // ab
    abTestData:{}, // ab
    abTestId:'', // abId

    // userInfo
    userInfo:{
      channelAttribution: {} // 渠道归因数据
    },

    // offer
    offerList:[],

    // 杂项
    timeZone: getTimeZone(), // 时区
    money:'', // 真金余额
    moneyData:{},
    isGoldCoin: false, // 是否达标提现

    // 跳转判断
    isOpenLeft: false, // 首页侧边栏是否打开
    isCashOutSignUp: false, // 是否从提现进入注册页面
    cashOutItem: {}, // 提现进入注册带过来的item数据

    // 项目是否第一次进入
    inited: false,

    // pwa
    deferredPrompt: null

  },
  mutations: {
    SET_CHANNEL_CALLBACK_STATUS(state, data){
      state.channelCallbackStatus = data
    },
    SET_ADJUST_DATA(state, data){
      state.adjustData = data
    },
    // 用户基本参数
    SET_BASIC_USER_CONFIG(state, data){
      state.basicConfig.uuid = data.uuid,
      state.basicConfig.package_name = data.package_name,
      state.basicConfig.os_language = data.os_language,
      state.basicConfig.product_id = data.product_id,
      state.basicConfig.version_code = data.version_code,
      state.basicConfig.version_name = data.version_name,
      state.basicConfig.os = data.os,
      state.basicConfig.android_id = data.android_id,
      state.basicConfig.google_aid = data.google_aid
    },
    // 渠道归因数据
    SET_CHANNEL_ATTRIBUTION(state, data){
      state.basicConfig.campaign = data.campaign
      state.basicConfig.channel = data.channel
      state.basicConfig.media_source = data.media_source
      state.basicConfig.adset = data.adset
      state.basicConfig.adgroup = data.adgroup
      console.log('SET_CHANNEL_ATTRIBUTION--state----basicConfig ', state.basicConfig)
    },
    // 设置offerlist
    SET_OFFERLIST(state, data){
      state.offerList = data
      console.log('state.offerList ',state.offerList )
    },
    SET_BASIC_CONFIG(state, data){
      state.basicConfig = data
      console.log('state.basicConfig',state.basicConfig)
    },
    SET_UUID(state, data){
      // 设置uuid
      state.basicConfig.uuid = data
      // 设置AndroidAid
      state.basicConfig.android_id = `${data}`
    },
    SET_GOOGLEAID(state, data){
      state.basicConfig.google_aid = `h5_${data}`
    },
    SET_LEFT_SWITCH(state, status){
      state.isOpenLeft = status
    },
    SET_AB_TEST_DATA(state, data){
      console.log('state.abTestData',state.abTestData)
      state.abTestData = data
    },
    SET_AB_TEST_ID(state, data){
      state.abTestId = data
    },
    SET_IS_CASH_OUT_SIGN_UP(state, data){
      state.isCashOutSignUp = data.status
      state.cashOutItem = data.cashItem
      console.log(' state.isCashOutSignUp', state.isCashOutSignUp)
      console.log(' state.cashOutItem', state.cashOutItem)
    },
    SET_MONEY(state, data){
      state.money = data
    },
    SET_IS_GOLD_COIN(state, data){
      state.isGoldCoin = data
      console.log('state.isGoldCoin ',state.isGoldCoin )
    },
    SET_MONEY_Data(state, data){
      state.moneyData = data
      console.log('state.moneyData ',state.moneyData )
    },
    SET_WEB_UUID(state, data){
      state.basicConfig.web_uuid = data
      console.log('   state.basicConfig.web_uuid',   state.basicConfig.web_uuid)
    },
    SET_INITED(state, data){
      state.inited = data
      console.log('   state.inited', state.inited)
    },
    setDeferredPrompt(state, event) {
      state.deferredPrompt = event;
    }
  },
  actions: {
    // 项目是否第一次进入
    setInited({commit}, data){
      commit('SET_INITED',data)
    },
    // 设置初始化参数basic
    setBasicConfig({commit}, data){
      commit('SET_BASIC_CONFIG',data)
    },
    // // 设置基本用户参数
    // setBasicUserConfig({commit}, data){
    //   commit('SET_BASIC_USER_CONFIG',data)
    // },
    // 设置渠道归因数据
    setChannelAttribution({commit}, data){
      commit('SET_CHANNEL_ATTRIBUTION',data)
    },
    // 设置offerlist
    setOfferList({commit}, data){
      commit('SET_OFFERLIST',data)
    },
    // 判断adjust是否已实例化状态
    setChannelCallbackStatus({commit}, data){
      commit('SET_CHANNEL_CALLBACK_STATUS',data)
    },
    // 设置adjust归因数据
    setAdjustData({commit}, data){
      commit('SET_ADJUST_DATA',data)
    },

    // 设置 uuid，用于webview自动登录
    setUuid({commit}, data){
      commit('SET_UUID',data)
    },
    // 设置googleAid
    setGoogleAid({commit}, data){
      commit('SET_GOOGLEAID',data)
    },
    // 设置首页侧边栏状态
    setLeftSwitch({commit}, status){
      commit('SET_LEFT_SWITCH',status)
    },
    // 设置AB数据
    setAbTestData({commit}, data){
      commit('SET_AB_TEST_DATA',data)
    },
    // 配置ab用户群id
    setAbTestId({commit}, data){
      commit('SET_AB_TEST_ID',data)
    },
    // 提现时跳转到注册页面
    setIsCashOutSignUp({commit}, data){
      commit('SET_IS_CASH_OUT_SIGN_UP',data)
    },
    // 设置真金余额
    setMoney({commit}, data){
      commit('SET_MONEY',data)
    },
    // 设置money数据对象
    setMoneyData({commit}, data){
      commit('SET_MONEY_Data',data)
    },
    // 设置是否已达标提现
    setIsGoldCoin({commit}, data){
      commit('SET_IS_GOLD_COIN',data)
    },
    // 设置web_uuid
    setWebUUID({commit}, data){
      commit('SET_WEB_UUID',data)
    },
  },
  getters: {
    // 定义用户模块的 getters
  }
};

export default system;
