import "@babel/polyfill";
import Es6Promise from "es6-promise";
require("es6-promise").polyfill();
Es6Promise.polyfill();


import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VConsole from 'vconsole'

// 国际化多语言
import i18n from '../src/language/i18n.js'

// 引入全局样式 @/src/assets/css/common.css，在app.vue文件引入

// 引入样式框架 vantUI，在vue.config.js内配置

// 自适应配置，使用 amfe-flexible 和 postcss-pxtorem（postcss-pxtorem在vue.config.js内配置）
import 'amfe-flexible'

// 仅在开发环境中初始化vConsole
if (process.env.NODE_ENV !== 'production') {
  new VConsole();
}

// pwa初始化事件
window.addEventListener('beforeinstallprompt', (e) => {
  console.log('初始化pwa');
  e.preventDefault();
  store.commit('system/setDeferredPrompt', e); // Store the event in Vuex
});


// 挂载
createApp(App).use(store).use(router).use(i18n).mount("#app");
