
// 导入需要的埋点SDK 或者其他相关埋点接口
import { snailTrack } from "@/api/config";
import md5 from 'md5'
import store from '@/store/index'
import { setCookie, getCookie, removeCookie } from '@/utils/auth'
// import { logEvent } from 'firebase/analytics';
// import { analytics } from '../firebase';
import { AESencrypt, AESdecrypt, RSAencrypt } from '@/utils/encryp'



// 定义通用的埋点方法
const trackEvent = (eventName, eventData, src) => {

  /*
    信息
    */
  // 时间戳
  let tims = new Date().getTime().toString();
  // 用户信息
  let userInfo = {}
  if(typeof getCookie('uIf') === 'undefined'){
    userInfo = {}
  }else{
    userInfo = JSON.parse(getCookie('uIf'))
  }
  // let userInfo = getCookie('uIf') == undefined ? JSON.parse(getCookie('uIf')) : ''
  // console.log('getCookie(uIf)',getCookie('uIf'))
  // basic信息
  let basic = store.state.system.basicConfig
  // 判断操作系统 1：android；2:ios；3: pc
  function detectOS() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Android设备
    if (/android/i.test(userAgent)) {
      return 1;
    }
    // iOS设备
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 2;
    }
    // 其他情况，为PC
    return 3;
  }
  // 浏览器版本
  const userAgent = navigator.userAgent;
  let browserVersion = "";
  if (userAgent.match(/Firefox\/([0-9.]+)/)) {
    // Firefox 浏览器
    browserVersion = userAgent.match(/Firefox\/([0-9.]+)/)[1];
  } else if (userAgent.match(/Chrome\/([0-9.]+)/)) {
    // Chrome 浏览器
    browserVersion = userAgent.match(/Chrome\/([0-9.]+)/)[1];
  } else if (userAgent.match(/Safari\/([0-9.]+)/)) {
    // Safari 浏览器
    browserVersion = userAgent.match(/Safari\/([0-9.]+)/)[1];
  } else if (userAgent.match(/MSIE ([0-9.]+)/)) {
    // IE 浏览器
    browserVersion = userAgent.match(/MSIE ([0-9.]+)/)[1];
  } else if (userAgent.match(/Trident\/.*rv:([0-9.]+)/)) {
    // IE11 浏览器
    browserVersion = userAgent.match(/rv:([0-9.]+)/)[1];
  } else if (userAgent.match(/Edge\/([0-9.]+)/)) {
    // Edge 浏览器
    browserVersion = userAgent.match(/Edge\/([0-9.]+)/)[1];
  }

  /*
    埋点数据
    */
  const baseEventData = {
    // **埋点事件**
    event: eventName,
    // **埋点基础字段**
    // add_timestamp:'', // 服务器时间戳
    // sip:'', // 服务器IP
    product_id: process.env.NODE_ENV !== "production" ? '210' :  basic ? basic.product_id : '' , // 产品ID ,测试时默认使用210
    uuid: basic ? basic.uuid : '', // uuid
    web_uuid: store.state.system.adjustData ? store.state.system.adjustData.web_uuid : '', // adjust uuid
    time_stamp: tims, // 客户端日志打印时间
    package_name: basic ? basic.package_name : '', // app包名
    country: userInfo ? userInfo.country : '', // 国家编码
    ipv4: userInfo ? userInfo.ip : '', // ip地址
    media_source: basic ? basic.media_source : 'Organic', // 渠道
    campaign: basic ? basic.campaign : null, // 子渠道
    version_code: basic ? basic.version_code : '', // 网站版本号
    version_name: basic ? basic.version_name : '', // 网站版本名
    google_aid: basic ? basic.google_aid : '', // google/Apple 唯一标识
    stats_sdk_version:'', // 统计sdk版本名，无，传空字符串，''
    abtest: store.state.system.abTestId, // AB用户群id
    install_days:'', // 安装天数
    is_upgrade_user:'', // 升级用户，无，传空字符串，''
    process_uuid:'', // 进程uuid，无，传空字符串，''
    os: detectOS(), // 操作系统
    os_version: navigator.platform ? navigator.platform : '', // 操作系统版本
    os_language: navigator.language ? navigator.language : '', // 浏览器语言
    phone_model: navigator.userAgent ? navigator.userAgent : '', // 手机型号
    net: navigator.connection ? navigator.connection.effectiveType : '', // 网络类型
    memory: '', // 内存，无，传空字符串，''
    dpi: window.devicePixelRatio * 96, // DPI
    screen_wh: document.body.clientWidth + "x" + document.body.clientHeight, // 分辨率
    screen_inchu: window.screen.width + "x" + window.screen.height, // 屏幕尺寸
    imei:'', // IMEI 浏览器无法获取，传空字符串，''
    imsi:'', // IMSI 浏览器无法获取，传空字符串，''
    src: src, // 协议号
    key_ext: { // 扩展字段
      viewport_userAgent: navigator.vendor ? navigator.vendor : '', // 浏览器厂商信息
      viewport_version: browserVersion, // 浏览器版本
      cookieEnabled: navigator.cookieEnabled ? navigator.cookieEnabled : '', // 是否启用cookie
    },
  };
  // 合并基础埋点值和传入的埋点数据
  const mergedEventData = [{ ...baseEventData, ...eventData }];
  // const mergedEventData = { ...baseEventData, ...eventData };

  /*
    snail埋点
    */

  //签名加密
  const key = 'L9gh=p%Eozfn2#@';
  const request_data_str = AESencrypt(JSON.stringify(mergedEventData), 'MM1B4I6w23QhzHhG');
  const sign = md5(key + request_data_str + key + tims + key);
  let obj = {
    request_data: request_data_str,
    timestamp: tims,
    sign: sign,
  }
  snailTrack(obj).then(res => {
    console.log('==SNAIL统计-成功==', eventName + '：', mergedEventData)
  }).catch((error) => {
    console.log('error',error)
    console.log('==SNAIL统计-失败==', eventName + '：', mergedEventData)
  })

  /*
    谷歌埋点
    */
  try {
    gtag('event', eventName, {});
    console.log('==谷歌统计-成功==', eventName)
  } catch (e) {
    console.log('==谷歌统计-失败==', eventName)
  }

  /*
    facebook埋点
    */
  try {
    fbq("track", eventName);
    console.log('==FabceBook埋点-成功==', eventName)
  } catch (e) {
    console.log('==FabceBook埋点-失败==', eventName)
  }

};

// 导出埋点方法，以便其他页面调用
export default trackEvent;
