import Cookies from 'js-cookie' // 引入cookie库

export function getCookie(cookieName) {
  return Cookies.get(cookieName)
}

export function setCookie(cookieName,cookieVal) {
  return Cookies.set(cookieName, cookieVal, { expires: new Date('9999-12-31T23:59:59') })
}

export function removeCookie(cookieName) {
  return Cookies.remove(cookieName)
}
