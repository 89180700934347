import { getCookie, setCookie } from "@/utils/auth";

const user = {
  namespaced: true, // 启用模块命名空间
  state: {

  },
  mutations: {

  },
  actions: {

  },
  getters: {
    // 定义用户模块的 getters
  }
};

export default user;
